import './App.css';
import { ethers } from 'ethers';
import { useEffect, useState } from 'react';
import token from './abis/token.json'
import farmland from './abis/farmland.json'
import Flyer from './images/Flyer.png'
import Starter from './images/Starter.png'
import Rewards from './images/Rewards.png'
import moment from 'moment/moment';
import Loading from './components/Loading';

function App() {
  var [account, setAccount] = useState("Connect Wallet")
  var [amount, setAmount] = useState("")
  var [stakeText, setstakeText] = useState("Stake $MSHARE")
  var [claimText, setclaimText] = useState("Claim Rewards")
  var [unstakeText, setunstakeText] = useState("Unstake $MSHARE")
  var [unstakeAllText, setunstakeAllText] = useState("Unstake All")
  //righthand variables
  var [userBalance, setuserBalance] = useState(0)
  var [userStaked, setuserStaked] = useState(0)
  var [userWeight, setuserWeight] = useState(0)
  //lefthand variables
  var [endtime, setendtime] = useState(0)
  var [epoch, setepoch] = useState(null)
  var [epochRewards, setepochRewards] = useState(<Loading/>)
  var [totalStaked, settotalStaked] = useState(<Loading/>)
  //contract addresses
  const tokenAddress = "0xC8Ca9026Ad0882133Ef126824F6852567c571A4E";
  const farmlandAddress = "0x3bb0bf268765c1De3370b2d66C59e7be206FeD78";
  //RPC provider
  const rpcProvider = "https://rpc.ankr.com/fantom";
  //countdown params
  const countdownText = "Staking Has Started"
  const startTime = 1666641600
  const [countdown, setCountdown] = useState("- Days --:--:--")
  //useffect for countdown and getting info
  useEffect(()=>{
    getInfo()
    setInterval(()=>getInfo(),10000)
    setInterval(()=>{
      const timeRemaining = startTime-(Date.now()/1000).toFixed(0)
      const duration = moment.duration(timeRemaining,"seconds")
      setCountdown(`${duration.days()} Days ${duration.hours()} Hours ${duration.minutes()} Minutes ${duration.seconds()} Seconds`)
    }, 1000)
  },[])
  //functions
  //error Handler
  async function errorHandler(ex){
    if(ex.error === undefined){
      alert(ex.message)
    }else{
      alert(ex.error.data.message)
    }
  }
  //connect function
  async function connect(){
    if(typeof window.ethereum !== 'undefined'){
      const provider = new ethers.providers.Web3Provider(window.ethereum)
      const chainId = await provider.getNetwork()
      if(chainId.chainId !== 250){
        alert("Please connect to Fantom Opera network!")
        window.location.reload()
      }else{
        try{
          const accounts = await provider.send("eth_requestAccounts", [])
          setAccount(accounts[0].slice(0,7)+"...")
          window.ethereum.on("accountsChanged", () => window.location.reload())
          window.ethereum.on("chainChanged", () => window.location.reload())
          getUserInfo()
        }catch(ex){
          errorHandler(ex)
        }
      }  
    }else{
      alert("Please install MetaMask or use MetaMask browser!")
    }
  }
  //stake function
  async function stake(){
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const allowance = await checkAllowance()
    if(allowance < amount){
      await approve()
    }
    try{
      setstakeText("Staking...")
      const farmlandContract = new ethers.Contract(farmlandAddress,farmland.abi,provider.getSigner())
      const stakeAmount = ethers.utils.parseEther(amount.toString())
      const stake = await farmlandContract.stake(stakeAmount)
      setstakeText("Please Wait")
      await stake.wait()
      alert(`You have successfully staked ${amount} $MSHARE!`)
    }catch(ex){
      errorHandler(ex)
    }finally{
      getUserInfo()
      setstakeText("Stake $MSHARE")
    }
  }
  //claim function
  async function claim(){
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const farmlandContract = new ethers.Contract(farmlandAddress,farmland.abi,provider.getSigner())
    try{
      setclaimText("Claiming Rewards")
      const claim = await farmlandContract.claimRewards()
      setclaimText("Please Wait")
      await claim.wait()
      alert("You have successfully claimed your rewards!")
    }catch(ex){
      errorHandler(ex)
    }finally{
      setclaimText("Claim Rewards")
    }
  }
  //unstake function
  async function unstake(){
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const farmlandContract = new ethers.Contract(farmlandAddress,farmland.abi,provider.getSigner())
    try{
      setunstakeText("Unstaking...")
      const unstakeAmount = ethers.utils.parseEther(amount.toString())
      const unstake = await farmlandContract.unstake(unstakeAmount)
      setunstakeText("Please Wait")
      await unstake.wait()
    }catch(ex){
      errorHandler(ex)
    }finally{
      getUserInfo()
      setunstakeText("Unstake $MSHARE")
    }
  }
  //unstake all function
  async function unstakeAll(){
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const farmlandContract = new ethers.Contract(farmlandAddress,farmland.abi,provider.getSigner())
    try{
      setunstakeAllText("Unstaking All...")
      const unstake = await farmlandContract.unstakeAll()
      setunstakeAllText("Please Wait")
      await unstake.wait()
    }catch(ex){
      errorHandler(ex)
    }finally{
      getUserInfo()
      setunstakeAllText("Unstake All $MSHARE")
    }
  }
  //approve function
  async function approve(){
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const tokenContract = new ethers.Contract(tokenAddress,token.abi,provider.getSigner())
    const toApprove = ethers.utils.parseEther(amount.toString())
    try{
      setstakeText("Approving...")
      const approve = await tokenContract.approve(farmlandAddress, toApprove)
      setstakeText("Please Wait")
      await approve.wait()
    }catch(ex){
      errorHandler(ex)
    }finally{
      setstakeText("Stake $MSHARE")
    }
  }
  //check allowance function
  async function checkAllowance(){
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const accounts = await provider.send("eth_requestAccounts", [])
    const tokenContract = new ethers.Contract(tokenAddress,token.abi,provider.getSigner())
    const allowanceBigNumber = await tokenContract.allowance(accounts[0], farmlandAddress)
    const allowance = ethers.utils.formatUnits(allowanceBigNumber,18)
    return allowance
  }
  //general info function
  async function getInfo(){
    const provider = new ethers.providers.JsonRpcProvider(rpcProvider)
    const farmlandContract = new ethers.Contract(farmlandAddress,farmland.abi,provider)
    const endTimestampBigNumber = await farmlandContract.endTimestamp()
    const endTimestamp = ethers.utils.formatUnits(endTimestampBigNumber,0)
    const getEpochBigNumber = await farmlandContract.epoch()
    const getEpoch = Number(ethers.utils.formatUnits(getEpochBigNumber,0))
    const totalStakedBigNumber = await farmlandContract.totalStaked()
    setepochRewards(1000)
    settotalStaked(Number(ethers.utils.formatUnits(totalStakedBigNumber,18)).toFixed(4))
    setendtime(endTimestamp)
    setepoch(getEpoch)
  }
  //user info function
  async function getUserInfo(){
    setuserBalance(<Loading/>)
    setuserStaked(<Loading/>)
    setuserWeight(<Loading/>)
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const accounts = await provider.send("eth_requestAccounts",[])
    const tokenContract = new ethers.Contract(tokenAddress,token.abi,provider.getSigner())
    const farmlandContract = new ethers.Contract(farmlandAddress,farmland.abi,provider.getSigner())
    const balanceBigNumber = await tokenContract.balanceOf(accounts[0])
    const balance = Number(ethers.utils.formatUnits(balanceBigNumber,18)).toFixed(4)
    const stakedBigNumber = await farmlandContract.userStakedAmount(accounts[0])
    const staked = Number(ethers.utils.formatUnits(stakedBigNumber,18)).toFixed(4)
    const weightBigNumber = await farmlandContract.stakerWeight(accounts[0])
    const weight = Number(ethers.utils.formatUnits(weightBigNumber,18)*100).toFixed(4)
    setuserBalance(balance)
    setuserStaked(staked)
    setuserWeight(weight)
  }
  return (
    <div className="App">
      <div id='Topbar'>
        <a href='https://worldofmagik.com'>
          <h1>World of Magik</h1>
          <h2>Prerelease Alpha</h2>
        </a>
        <div id = 'Menu'>
        <a href='https://magikdotfinance.gitbook.io/magik-arena/'>Documentation</a>
        <a href='https://linktr.ee/worldofmagik'>Important Links</a>
        <a href='https://twitter.com/worldofmagik'>Twitter</a>
        <div>
        </div>
        </div>
        {/* <button onClick={connect}>{account}</button> */}
      </div>
      {/* <h1>{countdownText}</h1>
      <h4>{countdown}</h4> */}
      <div id='Container'>
      <div style={{display:"grid",gap:"10px",margin:"0px 10px 50px 50px"}}>
        <h1>World of Magik</h1>
        <h2>Are you ready to step into the arena?</h2>
        <div id = 'Menu2'>
        <button onClick={() => window.open("https://www.testnet.worldofmagik.com")}>Play Now!</button>
        </div>
        <img id='Flyer' src={Flyer} alt="Flyer"/>
      </div>

      </div>
    </div>
  );
}

export default App;
